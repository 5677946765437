let slideshowSwiper = new Swiper('.slideshow .swiper', {
	slidesPerView: 'auto',
	//spaceBetween: 24,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

recommendedSwiper = new Swiper('.sezione-recommended .swiper', {
	slidesPerView:  'auto',
	spaceBetween: 16,
	slidesOffsetBefore: 16,
	slidesOffsetAfter: 16,
	breakpoints: {
		768: {
			spaceBetween: 32,
		}
	}
});

highlightsSwiper = new Swiper('.sezione-highlights .swiper', {
	slidesPerView:  'auto',
	spaceBetween: 16,
	slidesOffsetBefore: 16,
	slidesOffsetAfter: 16,
	breakpoints: {
		768: {
			spaceBetween: 32,
		}
	}
});