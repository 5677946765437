//================================= GO TO TOP

import { goToByScroll } from './go-to-by-scroll';

const body = $('body');

let go_to_top = $('#scroll-to-top');

go_to_top.on('click', function(){
	goToByScroll(body, 0, 600);
});

